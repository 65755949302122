import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {apiUrl} from "../App";

interface IStoreItem {
    title: string,
    category: string,
    pages: number,
    isbn: string,
    slug: string,
    description: string,
    author: string,
    price: number,
    published: boolean,
    image: string,
}

/* One individual item on the grid */
const StoreGridItem = (props: any) => {
    const {item} = props
    const url = `/butik/${item?.slug}`
    console.log("item", item)
    return (
        <article className="shop-item-column">
            <div className="shop-item-column-image">
                <img src={item?.image}/>
            </div>
            <div className="shop-item-column-content">
                <h2>&nbsp;</h2>
                <h2>{item?.author}</h2>
                <h1><a href={url}>{item?.title}</a></h1>
                <h2>&nbsp;</h2>
                <h2>{item?.category}</h2>
                <h2>{item?.pages} sidor</h2>
                <h2>ISBN: {item?.isbn}</h2>
                <h2>&nbsp;</h2>
                <h2>{item?.price} :-</h2>
            </div>

        </article>
    )
}

/**
 * View that shows a grid of available store items
 * @param props
 * @constructor
 */
export const StoreItemElement = (props: any) => {
    const {item} = props
    return (
        item ? <StoreGridItem item={item}/> : null
    )
}

export const StoreItemDetail = (props: any) => {
    const {slug} = useParams()
    const [item, setItem] = useState<IStoreItem | null>(null)
    useEffect(() => {
        const baseUrl = apiUrl()
        fetch(`${baseUrl}/api/store/${slug}/`)
            .then(response => response.json())
            .then(setItem)
            .catch(error => console.warn(error))
    }, [])

    const emailContent = (item: IStoreItem) => {
        return `mailto:info@medborgarhuset.se?subject=Bokbeställning Hej! Jag vill beställa\n${item.title}`
    }
    return (
        item ?
            <>
                <div className="breadcrumbs">
                    <Link to={"/butik"}>Butik &gt; {item?.title}</Link>
                </div>
                <main>
                    <section>
                        <article>
                            <div className="text-content shop-detail">
                                <div className="article-main-image">
                                    <img src={item.image} className="shop-item-image"/>
                                    <h2>{item.category}</h2>
                                    <h2>{item.pages} sidor</h2>
                                    <h2>ISBN: {item.isbn}</h2>
                                    <h2>{item.price} :-</h2>
                                </div>

                                <div className="text-content article-left-content">
                                    <h2>{item.author}</h2>
                                    <h1>{item.title}</h1>
                                    <p>{item.description}</p>
                                    <p>Köp boken genom att <a href={emailContent(item)}>maila oss</a></p>
                                </div>
                            </div>
                        </article>
                    </section>
                </main>
            </>
            : null
    )
}

/**
 * The main store page
 * @param props
 * @constructor
 */
export const Store = (props: any) => {

    const [items, setItems] = useState<IStoreItem[] | null>(null)

    useEffect(() => {
        const baseUrl = apiUrl()
        fetch(`${baseUrl}/api/store/`)
            .then(response => response.json())
            .then(data => data.results)
            .then(setItems)
    }, [])

    return (
        <main>
            <section className="">
                <div className="article-center-content store-grid-or-block">
                    {
                        items?.map((item: IStoreItem) => {
                                return (<StoreItemElement key={item.isbn} item={item}/>)
                            }
                        )
                    }
                </div>
            </section>
        </main>
    )
}
